import { Link } from "gatsby";
import React, { Component } from "react";

interface Props {
  link?: string;
  title: string;
  className?: string;
  onClick?: () => void;
}

export default class Button extends Component<Props> {
  render() {
    const linkClassName =
      "w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10";
    let defaultClassName = "rounded-md shadow cursor-pointer";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }
    return (
      <div className={defaultClassName} onClick={this.props.onClick}>
        {this.props.link ? (
          <Link to={this.props.link} className={linkClassName}>
            {this.props.title}
          </Link>
        ) : (
          <p className={linkClassName}>{this.props.title}</p>
        )}
      </div>
    );
  }
}
